import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";

window.$ = window.jQuery = require("jquery");

export default {
    name: "RolPage",
    data() {
        let ss = new MainService();
        return {
            msg: "RolPage",
            ss: ss,
            ajax: {
                "url": ss.getGestiones(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'idGestion', name: 'idGestion', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N°', orderable: false, searchable: false },
                { data: 'gestion', name: 'gestion', title: 'Gestión' },
                { data: 'descripcion', name: 'descripcion', title: 'Descripción' },
                { data: 'numero', name: 'numero', title: 'Docentes en Lista' },
                { data: 'evaluados', name: 'evaluados', title: 'Docentes Evaluados' },
                { data: 'fechaAlta', name: 'fechaAlta', title: 'Fecha de Creación' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            rols: [],
            rol2:{},
     
            rol: [],
            isLoading: false,
            errorBag: {},
            auth : null,
        };
    },
    methods: {
        newRol() {
            this.rol = {};
            this.$refs['frm-rol'].show();
        },
     
        showGestiones(id) {
          
            this.id = id;
            this.isLoading = true;
            this.ss.showGestiones(id)
              .then((result) => {
                let response = result.data;
                this.rol = response.data[0];
                this.rol2 = response.data[0];
              
                this.$refs['view-rol'].show();
                this.isLoading = false;
              })
              .catch(error => {
                console.log(error);
                this.isLoading = false;
              });
          },
        editRol() {
            this.$refs['frm-rolEdit'].show();
            this.$refs['view-rol'].hide();
        },
        cancelRol() {
            if (this.rol.role_id) {
                this.$refs['view-rol'].show();
            }
            this.$refs['frm-rol'].hide();
        },
        saveGestiones() {
            this.ss.storeGestiones(this.rol).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-rol'].hide();
                    this.$refs['datatable-gestion'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        updateGestiones() {
            try {
                const id = this.id;
                console.log("updateGestiones", id);
        
                this.ss.updateGestiones({
                    id: id,
                    gestion: this.rol2.gestion,
                    descripcion: this.rol2.descripcion,
                })
                .then(result => {
                    console.log(result);
                    let response = result.data;
        
                    this.$bvToast.toast(response.msg, {
                        title: 'Correcto',
                        variant: 'success',
                        autoHideDelay: 5000
                    });
        
                    console.log(response);
                    this.$refs['frm-rolEdit'].hide();
                    this.$refs['datatable-gestion'].reload();
                })
                .catch(error => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast('Problema al guardar el registro, favor verificar los datos', {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    });
                });
            } catch (error) {
                console.error('Error in updateRol:', error);
            }
        },
        
        deleteGestiones(id) {
            
             id = this.id; 
        
            this.$swal({
                title: "¿Estás seguro que deseas eliminar?",
                text: "Esta acción es irreversible.",
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                buttons: true,
                dangerMode: true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.ss.destroyGestiones({ id: id })  
                        .then((result) => {
                            let response = result.data;
                            console.log(response);
                            this.$bvToast.toast(
                                response.msg, // Aquí estás utilizando el mensaje del backend
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )
                            this.$refs['view-rol'].hide();
                            this.$refs['datatable-gestion'].reload();
                        })
                        .catch(error => {
                            console.log(error);
                        })
                } else {
                    // No hacer nada si el usuario cancela la eliminación
                }
            });
        },
        
        
        draw() {
            window.$('.btn-datatable-Gestion').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showGestiones(data);
            });
        }
    },
    components: {
        dataTable,
     
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        }else{
            this.auth = persona;
        }
    }
};
