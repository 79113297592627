import { render, staticRenderFns } from "./DashboardLayout.vue?vue&type=template&id=37d1ac1c&scoped=true"
import script from "./DashboardLayout.vue?vue&type=script&lang=js"
export * from "./DashboardLayout.vue?vue&type=script&lang=js"
import style0 from "./DashboardLayout.vue?vue&type=style&index=0&id=37d1ac1c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37d1ac1c",
  null
  
)

export default component.exports