import { render, staticRenderFns } from "./ProfesionesPage.vue?vue&type=template&id=8796b688"
import script from "./ProfesionesPage.js?vue&type=script&lang=js&external"
export * from "./ProfesionesPage.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports