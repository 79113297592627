import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";

window.$ = window.jQuery = require("jquery");

export default {
    name: "RolPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ClasificacionDocentePage",
            ss: ss,
            ajax: {
                "url": ss.indexClasificacionDocente(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N°', orderable: false, searchable: false },
                { data: 'Clasificacion', name: 'Clasificacion', title: 'Clasificacion' },
                { data: 'Descripcion', name: 'Descripcion', title: 'Descripcion' },
                { data: 'Abreviatura', name: 'Abreviatura', title: 'Abreviatura' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                }, 
            ],

            clasificacion:{},
            clasificaciones: [],
            isLoading: false,
            errorBag: {},
            auth : null,
        };
    },
    methods: {
        newClasificacion() {
            this.clasificacion = {};
            this.$refs['frm-clasificacion'].show();
        },
     
        editClasificacion(id){
            this.clasificacion = {};
            this.ss.showClasificacionDocente(id)
            .then((result) => {
              let response = result.data;
              this.clasificacion = response.data[0];
              this.$refs['frm-clasificacion'].show();
            })
            .catch(error => {
              console.log(error);
              this.isLoading = false;
            });
        },

        showClasificacion(id) {
            this.clasificacion = {};
            this.isLoading = true;
            this.ss.showClasificacionDocente(id)
              .then((result) => {
                let response = result.data;
                this.clasificacion = response.data[0];
                this.$refs['view-clasificacion'].show();
                this.isLoading = false;
              })
              .catch(error => {
                console.log(error);
                this.isLoading = false;
              });
        },

        cancelShowClasificacion(){
            this.clasificacion = {};
            this.$refs['view-clasificacion'].hide();
        },

      
        cancelStoreClasificacion() {
            this.clasificacion = {};
            this.$refs['frm-clasificacion'].hide();
        },
        saveClasificacion() {
            this.ss.storeClasificacionDocente(this.clasificacion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.$refs['frm-clasificacion'].hide();
                    this.$refs['datatable-Clasificacion'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        
        deleteClasificacion(id) {

            this.$swal({
                title: "¿Estás seguro que deseas eliminar?",
                text: "Esta acción es irreversible.",
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                buttons: true,
                dangerMode: true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.ss.deleteClasificacionDocente({ id: id })  
                        .then((result) => {
                            let response = result.data;
                            console.log(response);
                            this.$bvToast.toast(
                                response.msg, 
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )
                            this.$refs['datatable-Clasificacion'].reload();
                        })
                        .catch(error => {
                            console.log(error);
                        })
                } else {
                    // No hacer nada si el usuario cancela la eliminación
                }
            });
        },
        
        
        draw() {
            window.$('.btn-datatable-Clasificacion').on('click', (evt) => {
                const buttonId = window.$(evt.target).closest('button').attr('id'); 
                
                if (buttonId.includes('details_')) {
                    const id = buttonId.replace('details_', ''); 
                    this.showClasificacion(id);
                } 
                else if (buttonId.includes('edit_')) {
                    const id = buttonId.replace('edit_', ''); 
                    this.editClasificacion(id);
                } 
                else if (buttonId.includes('delete_')) {
                    const id = buttonId.replace('delete_', ''); 
                    this.deleteClasificacion(id);
                }
            });
        }
    },
    components: {
        dataTable,
     
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        }else{
            this.auth = persona;
        }
    }
};
