import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";

window.$ = window.jQuery = require("jquery");

export default {
    name: "InicioDocentePage",
    data() {
        let ss = new MainService();
        return {
            msg: "InicioDocentePage",
            ss: ss,
            ajax: {
                "url": ss.indexInicioDocenteGestion(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'idDocente', name: 'idDocente', orderable: false, searchable: false,visible: false},
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N°', orderable: false, searchable: false },
                { data: 'Grado', name: 'Grados.Grado', title: 'Grado' ,width:'500px'},
                { data: 'Docente', name: 'Docentes.Docente', title: 'Docente' ,width:'500px'},
                { data: 'CI', name: 'CI', title: 'CI' ,width:'500px'},
                { data: 'especialidad', name: 'evadoc_docextra2020.especialidad', title: 'Especialidad' ,width:'500px'},
                { data: 'UnidadAcademica', name: 'UnidadesAcademicas.UnidadAcademica', title: 'UnidadAcademica' ,width:'500px'},
                
                { data: 'puntaje', name: 'evadoc_docextra2020.puntaje', title: 'Puntaje' ,width:'500px', render: function(data) {
                    if (data === null || data.trim() === '') {
                        return '';
                    }
                    // Redondear el valor del puntaje a 2 decimales
                    return parseFloat(data).toFixed(0);
                }},
                { data: 'semestresEMI', name: 'evadoc_docextra2020.semestresEMI', title: 'Semestre EMI' ,width:'500px'},
                {
                    data: 'idCategoria',
                    name: 'evadoc_docextra2020.idCategoria',
                    title: 'Categoria Calc.',
                    width: '500px',
                }
                
                ,
                { data: 'postula', name: 'evadoc_docextra2020.postula', title: 'Categorizado con' ,width:'500px'},
          /*       { data: 'dc', name: 'dc', title: 'Categoria SAGA' ,width:'500px'}, */
                { data: 'Gestion', name: 'c.Gestion', title: 'Gestion' ,width:'500px'},
                { data: 'IdPeriodoGestion', name: 'c.IdPeriodoGestion', title: 'PeriodoGestion' ,width:'500px'},
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],

            gestions: [],           
            datos: [], 
            uas: [],
            histo:[],    
            datosDoc: null,
            datosDocEmi: [],
            datosDocEmi2: '',
            datosDocEmi3: '',
            id:{},       
            docente:{},          
            niveles: [],
            categorias: [],
            nivelesFiltrados: []  ,
            iniciodocente: {},
            isLoading: false,
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            errorBag: {},
            auth : null,
          
        };
    },
    computed: {
        trimmedP1: {
          get() {
            return this.docente.p1 ? this.docente.p1.split('|')[0].trim() : '';
          },
          set(value) {
            // Si necesitas asignar un valor al modelo original
            this.docente.p1 = value;
          }
        },
        trimmedP2: {
            get() {
              return this.docente.p2 ? this.docente.p2.split('|')[1].trim() : '';
              // Utiliza [1] para obtener el segundo valor después de dividir la cadena
            },
            set(value) {
              const values = this.docente.p2.split('|');
              values[1] = value;
              this.docente.p2 = values.join('|');
            }
          }
        
      },
    methods: {
      aplicarFiltros() {
        let periodo = this.iniciodocente.IdPeriodoGestion;     
        let gest = this.iniciodocente.Gestion;     
        let u = this.iniciodocente.idUnidadAcademica;     
        console.log("Período de Gestión seleccionado:", periodo);
        console.log("Gestión seleccionada:", gest);
        this.ajax.data = function(d){
            d.IdPeriodoGestion = periodo
            d.Gestion = gest
            d.idUnidadAcademica = u
        }
        this.$refs['datatable-iniciodocente'].reloadAjax(this.ajax);
    },
    
    getUA() {
      this.ss.listUA().then(
          (result) => {
              let response = result.data;
              this.uas = response.data;
          }
      ).catch(error => {
          console.log(error);
      });
  },
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
              // Validación del tipo de archivo
              if (input.files[0].type !== 'application/pdf') {
                this.$bvToast.toast(
                  'El archivo seleccionado no es un Documento PDF',
                  {
                    title: 'Oops!',
                    variant: 'danger',
                    autoHideDelay: 5000
                  }
                );
                this.isLoadingFile = false;
                return;
              }
          
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]);
              var data = new FormData();
              data.append('File', input.files[0]);
              this.ss.uploadFile(data)
                .then(result => {
                  if (result.data.success) {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Correcto',
                        variant: 'info',
                        autoHideDelay: 5000
                      }
                    );
                    this.docente.fileName = result.data.data;
                  } else {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Oops!',
                        variant: 'danger',
                        autoHideDelay: 5000
                      }
                    )
                  }
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                });
            }
          },
          loadFile2(event) {
            this.isLoadingFile = true;
            let input = event.target;
            
            if (input.files && input.files[0]) {
              const file = input.files[0];
        
              // Crear un objeto URL para la previsualización de la imagen
              const reader = new FileReader();
              reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                  // Verificar las dimensiones de la imagen (por ejemplo, mínimo 1280x720)
                  const minWidth = 1280;
                  const minHeight = 720;
                  if (img.width < minWidth || img.height < minHeight) {
                    this.$bvToast.toast(
                      'La imagen debe tener al menos 1280x720 píxeles',
                      {
                        title: 'Oops!',
                        variant: 'danger',
                        autoHideDelay: 5000
                      }
                    );
                    this.isLoadingFile = false;
                    return;
                  }
        
                  // Todo está bien, proceder con la subida
                  const data = new FormData();
                  data.append('File', file);
                  this.ss.uploadFile(data)
                    .then(result => {
                      if (result.data.success) {
                        this.$bvToast.toast(
                          result.data.msg,
                          {
                            title: 'Correcto',
                            variant: 'info',
                            autoHideDelay: 5000
                          }
                        );
                        this.datosDoc.fotoCI = result.data.data;
                        this.updateDatosDocente();
                      } else {
                        this.$bvToast.toast(
                          result.data.msg,
                          {
                            title: 'Oops!',
                            variant: 'danger',
                            autoHideDelay: 5000
                          }
                        );
                      }
                      this.isLoadingFile = false;
                    })
                    .catch(error => {
                      console.log(error);
                      this.$bvToast.toast(
                        'Error subiendo archivo',
                        {
                          title: 'Oops!',
                          variant: 'danger',
                          autoHideDelay: 5000
                        }
                      );
                      this.isLoadingFile = false;
                    });
                };
                img.src = e.target.result;
              };
              reader.readAsDataURL(file);
            } else {
              this.isLoadingFile = false;
            }
          }
        
      ,
      loadFile3(event) {
        this.isLoadingFile = true;
        let input = event.target;
        
        if (input.files && input.files[0]) {
          const file = input.files[0];
    
          // Crear un objeto URL para la previsualización de la imagen
          const reader = new FileReader();
          reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
              // Verificar las dimensiones de la imagen (por ejemplo, mínimo 1280x720)
             /*  const minWidth = 1280;
              const minHeight = 720;
              if (img.width < minWidth || img.height < minHeight) {
                this.$bvToast.toast(
                  'La imagen debe tener al menos 1280x720 píxeles',
                  {
                    title: 'Oops!',
                    variant: 'danger',
                    autoHideDelay: 5000
                  }
                );
                this.isLoadingFile = false;
                return;
              } */
    
              // Todo está bien, proceder con la subida
              const data = new FormData();
              data.append('File', file);
              this.ss.uploadFile(data)
                .then(result => {
                  if (result.data.success) {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Correcto',
                        variant: 'info',
                        autoHideDelay: 5000
                      }
                    );
                    this.datosDoc.fotografia = result.data.data;
                    this.updateDatosDocente();
                  } else {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Oops!',
                        variant: 'danger',
                        autoHideDelay: 5000
                      }
                    );
                  }
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo archivo',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  );
                  this.isLoadingFile = false;
                });
            };
            img.src = e.target.result;
          };
          reader.readAsDataURL(file);
        } else {
          this.isLoadingFile = false;
        }
      },
        filtrarNivelesPorCategoria() {
            const categoriaSeleccionada = this.categorias.find(c => c.id === this.docente.tipo);

            if (categoriaSeleccionada) {
                // Filtrar los niveles según la categoría seleccionada
                this.nivelesFiltrados = this.niveles.filter(n => n.tipo === categoriaSeleccionada.id);
            } else {
                // Si no hay categoría seleccionada, mostrar todos los niveles
                this.nivelesFiltrados = this.niveles;
            }
        },
        getNivel() {
            this.ss.getNivel().then(
                (result) => {
                    let response = result.data;
                    this.niveles = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getCategoria() {
            this.ss.getCategoria().then(
                (result) => {
                    let response = result.data;
                    this.categorias = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        async showReportelist() {
          this.isLoading = true;
          try {
            let response = await this.ss.showReportelist(this.idDocente);
            console.log("Response:", response);
            this.datosDocEmi2 = response.data.html; // Asignar los datos recibidos
          } catch (error) {
            console.log(error);
          } finally {
            this.isLoading = false;
          }
        },

        async getdocente(id) {
          id = this.idDocente;
          this.isLoading = true;
          try {
            let response = await this.ss.getdocente(id);
            console.log("Response:", response);
            this.datosDocEmi3 = response.data; // Asignar los datos recibidos
          } catch (error) {
            console.log(error);
          } finally {
            this.isLoading = false;
          }
        },

        

        newMerito() {
            this.docente = {};
            this.$refs['frm-iniciodocente'].show();
        },
        showInicioDocente(id) {
            console.log("esteshowInicioDocente", id);
            this.idDocente = id;
            this.isLoading = true;
            this.ss.showInicioDocente(id)
              .then((result) => {
                let response = result.data;
                console.log("...",response);
                this.datos = response.data;
                this.docente = response.data;
                this.$refs['view-iniciodocente'].show();
                this.isLoading = false;
              })
              .catch(error => {
                console.log(error);
                this.isLoading = false;
              });
          },

          showInicioDocente2(id) {
            console.log("este29", id);
            this.isLoading = true;
            this.ss.showInicioDocente2(id)
                .then((result) => {
                    let response = result.data;
                    console.log("Response:", response);
                    
                    // Assuming response.data is an array, and you want to take the first item
                    if (response.success && response.data.length > 0) {
                        let docenteData = response.data[0]; // Assuming you want the first item
                        this.docente = {
                            id: docenteData.id,
                            tipo: docenteData.tipo,
                            nivel: docenteData.nivel,
                            p1: docenteData.params.split('|')[0], // Split params and take the first part
                            p2: docenteData.params.split('|')[1] ? docenteData.params.split('|')[1].trim() : '',
                            p3: docenteData.fecha, // Assuming fecha is what you want for p3
                            fileName: docenteData.filename // Assuming filename is what you want for fileName
                            // Add other properties as needed
                        };
                        
                        // Now docente object is populated with the received data
                    }
                    
                    this.$refs['frm-iniciodocenteEdit'].show(); // Show the form
                    this.isLoading = false;
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        listHistorial(id) {
            id = this.idDocente;
            console.log("estelistHistorial", id);
            this.ss.listHistorial(id)
                .then((result) => {
                    let response = result.data;
                    console.log("Response:", response);
                    if (response.success && response.data.length > 0) {
                        this.histo = response; // Asignar todo el objeto de respuesta
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                });
        }
,        
listDatosDocente(id) {
    id = this.idDocente;
    console.log("estelistDatosDocente", id);
    this.ss.listDatosDocente(id)
        .then((result) => {
            let response = result.data;
            console.log("Response:", response);
            if (response.success && response.data.length > 0) {
                this.datosDoc = response.data[0]; // Asignar el primer elemento de los datos
            }
        })
        .catch(error => {
            console.log(error);
            this.isLoading = false;
        });
},

      listDocenciaEMI(id) {
        id = this.idDocente;
        console.log("estelistDocenciaEMI", id);
        this.ss.listDocenciaEMI(id)
          .then((result) => {
              let response = result.data;
              console.log("Response:", response);
              if (response.success && response.data.length > 0) {
                  this.datosDocEmi = response; // Asignar el primer elemento de los datos
              }
          })
          .catch(error => {
              console.log(error);
              this.isLoading = false;
          });
        },

       

        showEditar() {
            this.$refs['frm-iniciodocente'].show();
        },
        cancelInicioDocente() {
            if (this.iniciodocente.id) {
                this.$refs['view-iniciodocente'].show();
            }
            this.$refs['frm-iniciodocente'].hide();
        },
        verArchivo(index) {
            // Obtener el elemento del array de datos correspondiente al índice
            const item = this.datos[index];
        
            // Verificar si hay un archivo cargado rutaApp +'storage/documentos/' +
            if (item.filename) {
              // Abrir una nueva ventana del navegador con el archivo
              window.open(this.rutaApp + 'storage/documentos/' + item.filename, "_blank");
            } else {
              // Mostrar un mensaje o realizar otra acción si no hay archivo cargado
              console.error("No hay archivo cargado para este elemento.");
            }
          },
        saveInicioDocente() {
        
            this.docente.docente = this.idDocente;
            console.log("hoy",this.docente.fileName)
            this.ss.storeInicioDocente(this.docente).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    console.log(response);
                    this.$refs['frm-iniciodocente'].hide();
                    this.actualizarTabla();
                  
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        update(id) {
        console.log("actualizar",id);
            const formData = {
                id: id,
                tipo: this.docente.tipo,
                nivel: this.docente.nivel,
                p1: this.docente.p1,
                p2: this.docente.p2,
                p3: this.docente.p3,
                fileName: this.docente.fileName
            };
        
            this.ss.update(formData)
                .then(result => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(response.msg, {
                        title: 'Correcto',
                        variant: 'success',
                        autoHideDelay: 5000
                    });
                    console.log(response);
                    this.$refs['frm-iniciodocenteEdit'].hide();
                    this.actualizarTabla();
                })
                .catch(error => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast('Problema al guardar el registro, favor verificar los datos', {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    });
                });
        },

        
        updateDatosDocente(id) {
          id= this.idDocente;
          console.log("actualizar",id);
              const formDatad = {
                  id: id,
                  especialidad: this.datosDoc.especialidad,
                  registro: this.datosDoc.registro,
                  NIT: this.datosDoc.NIT,
                  catresolucion: this.datosDoc.catresolucion,
                  fotoCI: this.datosDoc.fotoCI,
                  fotografia: this.datosDoc.fotografia
              };
          console.log("lol".formDatad);
              this.ss.updateDatosDocente(formDatad)
                  .then(result => {
                      console.log(result);
                      let response = result.data;
                      this.$bvToast.toast(response.msg, {
                          title: 'Correcto',
                          variant: 'success',
                          autoHideDelay: 5000
                      });
                      console.log(response);
                      this.listDatosDocente(this.id);

                  })
                  .catch(error => {
                      this.errorBag = error.response.data.errors;
                      this.$bvToast.toast('Problema al guardar el registro, favor verificar los datos', {
                          title: 'Error',
                          variant: 'danger',
                          autoHideDelay: 5000
                      });
                  });
          }
,        
        deleteInicioDocente(id) {
            let dataToSend = {
                id: id,
               
            };
        console.log("ssss",id);
            this.$swal({
              title: "¿Estás seguro de que deseas eliminar?",
              text: "¡Esta acción es irreversible!",
              icon: "warning",
              showCloseButton: true,
              showCancelButton: true,
              cancelButtonColor: '#d33',
              buttons: true,
              dangerMode: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.ss.destroyInicioDocente(dataToSend)
                  .then((result) => {
                    let response = result.data;
                    console.log(response);
        
                    this.$bvToast.toast(
                      response.msg,
                      {
                        title: 'Correcto',
                        variant: response.success ? 'success' : 'danger',
                        autoHideDelay: 5000
                      }
                    );
        
                    if (response.success) {
                      // Actualizar la tabla después de eliminar el registro
                      this.actualizarTabla();
                    }
                  })
                  .catch(error => {
                    console.log(error);
                  });
              }
             } )
        },
        actualizarTabla() {
            // Llama a showInicioDocente para obtener datos actualizados
            this.showInicioDocente(this.idDocente);
            
            // Puedes agregar lógica adicional aquí según tus necesidades
        },
     
        draw() {
            window.$('.btn-datatable-InicioDocente').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showInicioDocente(data);
            });
        }
    },
    components: {
        dataTable,
    
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }else{
            this.auth = persona;
            this.getCategoria();
            this.getNivel();
            this.getUA();
        }
    }
};
