<template>
<div>

  <!-- <div class="text-center" v-if="$route.meta.login" style="background: #25272e;height: 100vh;width: 100%;position: absolute;"> -->
  <!-- <div class="text-center" v-if="$route.meta.login" style="">
    
  </div> -->

  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar } ">
    <notifications ></notifications>

    <side-bar
      :sidebar-item-color="sidebarBackground"
      :sidebar-background-image="sidebarBackgroundImage"
      v-if="!$route.meta.sinmenu"
    >
      <!-- <mobile-menu slot="content"></mobile-menu> -->
      
      <!-- 
      <sidebar-link to="/repositorios">
        <md-icon>assignment</md-icon>
        <p>Repositorios </p>
      </sidebar-link> -->
      <sidebar-link to="/user">
        <md-icon>person</md-icon>
        <p>Perfil de Usuario</p>
      </sidebar-link>

      <sidebar-link to="/rol">
        <md-icon>assignment</md-icon>
        <p>Roles</p>
      </sidebar-link>

      <sidebar-link to="/persona">
        <md-icon>group</md-icon>
        <p>Usuarios</p>
      </sidebar-link>

      <!-- --------------------------------------------------- -->


      <!-- Acciones principales -->

      <sidebar-link to="/iniciodocente">
        <md-icon>assignment_ind</md-icon>
        <p>Inicio Docente</p>
      </sidebar-link>

      <sidebar-link to="/docentescategorizados">
        <md-icon>how_to_reg</md-icon>
        <p>Docentes Categorizados</p>
      </sidebar-link>

      <sidebar-link to="/iniciodocentegestion">
        <md-icon>dashboard_customize</md-icon>
        <p>Docentes por Gestión</p>
      </sidebar-link>

      <sidebar-link to="/docentesdesvinculados">
        <md-icon>block</md-icon>
        <p>Docentes Desvinculados</p>
      </sidebar-link>

      <sidebar-link to="/permanenciadocente">
        <md-icon>assignment</md-icon>
        <p>Permanencia Docente</p>
      </sidebar-link>


<!-- Parametricas -->

<sidebar-link to="/categoriadocente">
        <md-icon>engineering</md-icon>
        <p>Categoria Docente</p>
      </sidebar-link>

      <sidebar-link to="/clasificaciondocente">
        <md-icon>engineering</md-icon>
        <p>Clasificación Docente</p>
      </sidebar-link>

      <sidebar-link to="/Profesion">
        <md-icon>engineering</md-icon>
        <p>Profesion</p>
      </sidebar-link>

      <sidebar-link to="/Sistema">
        <md-icon>engineering</md-icon>
        <p>Sistema</p>
      </sidebar-link>

      <sidebar-link to="/Universidades">
        <md-icon>engineering</md-icon>
        <p>Universidades</p>
      </sidebar-link>

      <sidebar-link to="/gestiones">
        <md-icon>engineering</md-icon>
        <p>Gestiones de Categorización</p>
      </sidebar-link> 

      <sidebar-link to="/profesiones">
        <md-icon>engineering</md-icon>
        <p>Profesiones</p>
      </sidebar-link>

      <sidebar-link to="/gradoacademico">
        <md-icon>engineering</md-icon>
        <p>Grado Academico</p>
      </sidebar-link>
      

      



      

      



     <!--  <sidebar-link to="/dashboard">
        <md-icon>dashboard</md-icon>
        <p>Dashboard</p>
      </sidebar-link>

     


   

      
     
      <sidebar-link to="/table">
        <md-icon>content_paste</md-icon>
        <p>Table list</p>
      </sidebar-link>
      <sidebar-link to="/typography">
        <md-icon>library_books</md-icon>
        <p>Typography</p>
      </sidebar-link>
      <sidebar-link to="/icons">
        <md-icon>bubble_chart</md-icon>
        <p>Icons</p>
      </sidebar-link>
      <sidebar-link to="/maps">
        <md-icon>location_on</md-icon>
        <p>Maps</p>
      </sidebar-link>
      <sidebar-link to="/notifications">
        <md-icon>notifications</md-icon>
        <p>Notifications</p>
      </sidebar-link>

    

      <sidebar-link to="/upgrade" class="active-pro">
        <md-icon>unarchive</md-icon>
        <p>Upgrade to PRO</p>
      </sidebar-link> -->


    </side-bar>

    
    
    <div class="main-panel" v-if="!$route.meta.login">
      <top-navbar v-if="!$route.meta.sinheader"></top-navbar>

      <!-- <fixed-plugin
        :color.sync="sidebarBackground"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin> -->

      <dashboard-content v-if="!$route.meta.login"> </dashboard-content>
      <login-content v-if="$route.meta.login"> </login-content>

      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>

    <div class="" v-if="$route.meta.login">
      <top-navbar v-if="!$route.meta.sinheader"></top-navbar>



      <!-- <dashboard-content v-if="!$route.meta.login"> </dashboard-content> -->
      <login-content v-if="$route.meta.login"> </login-content>

      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
  </div>

  
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import LoginContent from "./ContentLogin.vue";
// import MobileMenu from "@/pages/Layout/MobileMenu.vue";
// import FixedPlugin from "./Extra/FixedPlugin.vue";

export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    // MobileMenu,
    LoginContent,
    // FixedPlugin,
  },
  data() {
    return {
      sidebarBackground: "green",
      sidebarBackgroundImage: require("@/assets/img/sidebar-2.png"),
      dropdownVisible: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
      console.log(this.dropdownVisible);
    },
  },
};
</script>
<style scoped>
/* Estilo para el submenú */
.submenu {
  padding-left: 20px;
}

.submenu sidebar-link {
  padding-left: 15px; /* Indentar los elementos del submenú */
}

.submenu sidebar-link:hover {
  background-color: #f0f0f0; /* O el color que prefieras para el hover */
}
</style>
