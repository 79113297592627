import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

window.$ = window.jQuery = require("jquery");

export default {
    name: "RolPage",
    data() {
        let ss = new MainService();
        return {
            msg: "RolPage",
            ss: ss,
            ajax: {
                "url": ss.getDocentes(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'idGestion', name: 'idGestion', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N°', orderable: false, searchable: false },
                { data: 'gestion', name: 'gestion', title: 'Gestión' },
              
                { data: 'UnidadAcademica', name: 'UnidadAcademica', title: 'Unidad Académica' },
                { data: 'Grado', name: 'Grado', title: 'Grado' },
                { data: 'Docente', name: 'Docente', title: 'Docente' },
                { data: 'CI', name: 'CI', title: 'CI' },
                { 
                    data: 'tipo', 
                    name: 'tipo', 
                    title: 'Tipo',
                    render: function(data, type, row, meta) {
                        return data == 1 ? 'Autoridad EMI' : 'Cat. Docente';
                    }
                },
                { data: 'categoriatxt', name: 'categoriatxt', title: 'Certificado con' },
                { data: 'numero', name: 'numero', title: 'N° Cert.' },
                { data: 'resolucion', name: 'resolucion', title: 'N° Resolución' },
             /*    { data: 'puntaje', name: 'puntaje', title: 'Puntaje de Cat.' }, */
                { data: 'puntaje', name: 'puntaje', title: 'Puntaje' , render: function(data) {
                  if (data === null || data.trim() === '') {
                      return '';
                  }
                  // Redondear el valor del puntaje a 2 decimales
                  return parseFloat(data).toFixed(0);
              }},
               /*  { data: 'semestresEMI', name: 'semestresEMI', title: 'Semestres EMI' }, */


              
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            rols: [],
            rol2:{},
            gests: [],
            rol: [],
            uas: [],
            isLoading: false,
            errorBag: {},
          
            auth : null,
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            datosDoc: null,
            idDocente: '',  // Initialize idDocente here
      Docentes: [],  // Example data array, replace with your actual data
      rol: {
        idDocente: '',  // Initialize rol.idDocente here
        Docente: ''  // Initialize rol.Docente here
      },
      selectedHitDoc: 0  // Initialize selectedHitDoc here
        };
        
    },
    methods: {
        loadFile2(event) {
            this.isLoadingFile = true;
            let input = event.target;
            
            if (input.files && input.files[0]) {
              const file = input.files[0];
        
              // Crear un objeto URL para la previsualización de la imagen
              const reader = new FileReader();
              reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                  // Verificar las dimensiones de la imagen (por ejemplo, mínimo 1280x720)
                  const minWidth = 1280;
                  const minHeight = 720;
                  if (img.width < minWidth || img.height < minHeight) {
                    this.$bvToast.toast(
                      'La imagen debe tener al menos 1280x720 píxeles',
                      {
                        title: 'Oops!',
                        variant: 'danger',
                        autoHideDelay: 5000
                      }
                    );
                    this.isLoadingFile = false;
                    return;
                  }
        
                  // Todo está bien, proceder con la subida
                  const data = new FormData();
                  data.append('File', file);
                  this.ss.uploadFile(data)
                    .then(result => {
                      if (result.data.success) {
                        this.$bvToast.toast(
                          result.data.msg,
                          {
                            title: 'Correcto',
                            variant: 'info',
                            autoHideDelay: 5000
                          }
                        );
                        this.datosDoc.fotoCI = result.data.data;
                        this.updateDatosDocente();
                      } else {
                        this.$bvToast.toast(
                          result.data.msg,
                          {
                            title: 'Oops!',
                            variant: 'danger',
                            autoHideDelay: 5000
                          }
                        );
                      }
                      this.isLoadingFile = false;
                    })
                    .catch(error => {
                      console.log(error);
                      this.$bvToast.toast(
                        'Error subiendo archivo',
                        {
                          title: 'Oops!',
                          variant: 'danger',
                          autoHideDelay: 5000
                        }
                      );
                      this.isLoadingFile = false;
                    });
                };
                img.src = e.target.result;
              };
              reader.readAsDataURL(file);
            } else {
              this.isLoadingFile = false;
            }
          }
        
      ,
  /*     printCertificado(idDocGes) {
            this.ss.printCertificado(idDocGes)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })        
    }, */
    printCertificado(idDocGes) {
      
      // Corregir la URL concatenando adecuadamente los parámetros
      let url = `${process.env.VUE_APP_MAIN_SERVICE}api/InicioDocente/printCertificado?idDocGes=${idDocGes}`;
      
      console.log(url);
      
      // Abrir en una nueva ventana o pestaña
      window.open(url, '_blank');
  },
  
      getUA() {
        this.ss.listUA().then(
            (result) => {
                let response = result.data;
                this.uas = response.data;
            }
        ).catch(error => {
            console.log(error);
        });
    },
      loadFile3(event) {
        this.isLoadingFile = true;
        let input = event.target;
        
        if (input.files && input.files[0]) {
          const file = input.files[0];
    
          // Crear un objeto URL para la previsualización de la imagen
          const reader = new FileReader();
          reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
              // Verificar las dimensiones de la imagen (por ejemplo, mínimo 1280x720)
             /*  const minWidth = 1280;
              const minHeight = 720;
              if (img.width < minWidth || img.height < minHeight) {
                this.$bvToast.toast(
                  'La imagen debe tener al menos 1280x720 píxeles',
                  {
                    title: 'Oops!',
                    variant: 'danger',
                    autoHideDelay: 5000
                  }
                );
                this.isLoadingFile = false;
                return;
              } */
    
              // Todo está bien, proceder con la subida
              const data = new FormData();
              data.append('File', file);
              this.ss.uploadFile(data)
                .then(result => {
                  if (result.data.success) {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Correcto',
                        variant: 'info',
                        autoHideDelay: 5000
                      }
                    );
                    this.datosDoc.fotografia = result.data.data;
                    this.updateDatosDocente();
                  } else {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Oops!',
                        variant: 'danger',
                        autoHideDelay: 5000
                      }
                    );
                  }
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo archivo',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  );
                  this.isLoadingFile = false;
                });
            };
            img.src = e.target.result;
          };
          reader.readAsDataURL(file);
        } else {
          this.isLoadingFile = false;
        }
      }
    
  ,
      showInicioDocente(id) {
        console.log("esteshowInicioDocente", id);
        this.idDocente = id;
        this.isLoading = true;
        this.ss.showInicioDocente(id)
          .then((result) => {
            let response = result.data;
            console.log("...",response);
            this.datos = response.data;
            this.docente = response.data;
            this.$refs['view-iniciodocente'].show();
            this.listDatosDocente();
            this.isLoading = false;
          })
          .catch(error => {
            console.log(error);
            this.isLoading = false;
          });
      },
        updateDatosDocente(id) {
            id= this.idDocente;
            console.log("actualizar",id);
                const formDatad = {
                    id: id,
                    especialidad: this.datosDoc.especialidad,
                    registro: this.datosDoc.registro,
                    NIT: this.datosDoc.NIT,
                    catresolucion: this.datosDoc.catresolucion,
                    fotoCI: this.datosDoc.fotoCI,
                    fotografia: this.datosDoc.fotografia
                };
            console.log("lol".formDatad);
                this.ss.updateDatosDocente(formDatad)
                    .then(result => {
                        console.log(result);
                        let response = result.data;
                        this.$bvToast.toast(response.msg, {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        });
                        console.log(response);
                        this.listDatosDocente(this.id);

                    })
                    .catch(error => {
                        this.errorBag = error.response.data.errors;
                        this.$bvToast.toast('Problema al guardar el registro, favor verificar los datos', {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        });
                    });
            }
,        
        aplicarFiltros() {
      
          let gest = this.rol.gestion; 
          let ua = this.rol.UnidadAcademica; 
            
            
            console.log("gest",gest)
            this.ajax.data = function(d){
               
              d.gestion = gest
              d.UnidadAcademica = ua
                
            }
            this.$refs['datatable-cdocentes'].reloadAjax(this.ajax);
        },
        
        getDocentesC() {
            this.ss.getDocentesList().then(
                (result) => {
                    let response = result.data;

                    console.log(response);
                    this.Docentes= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        serializerFunction(item) {
            // Ensure item is not null or undefined
            if (item && item.Docente) {
              return item.Docente;
            }
            return '';  // Return an empty string or a default value if item is null/undefined
          },
        newRol() {
            this.rol = {};
            this.$refs['frm-rol'].show();
        },
        checkCategoria() {
          if (this.rol2.idCategoria == 2 && this.rol.puntaje < 701) {
              this.$bvToast.toast('No puede seleccionar Categoria D porque su puntaje es menor a 701.', {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 5000
            });
              this.rol2.idCategoria = ''; // Reiniciar el valor seleccionado
          }

          if (this.rol2.idCategoria == 3 && this.rol.puntaje < 1701) {
            this.$bvToast.toast('No puede seleccionar Categoria C porque su puntaje es menor a 1701.', {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000
          });
            this.rol2.idCategoria = ''; // Reiniciar el valor seleccionado
          }

          if (this.rol2.idCategoria == 4 && this.rol.puntaje < 2701) {
            this.$bvToast.toast('No puede seleccionar Categoria B porque su puntaje es menor a 2701.', {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000
          });
            this.rol2.idCategoria = ''; // Reiniciar el valor seleccionado
          }

          if (this.rol2.idCategoria == 5 && this.rol.puntaje < 3701) {
            this.$bvToast.toast('No puede seleccionar Categoria A porque su puntaje es menor a 3701.', {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000
          });
            this.rol2.idCategoria = ''; // Reiniciar el valor seleccionado
          }

          if (this.rol2.idCategoria == 6 && this.rol.puntaje < 4701) {
            this.$bvToast.toast('No puede seleccionar Categoria EMÉRITO porque su puntaje es menor a 4701.', {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000
          });
            this.rol2.idCategoria = ''; // Reiniciar el valor seleccionado
          }
      },
        showDocentesC(id) {
          
            this.id = id;
            console.log(id);
            this.isLoading = true;
            this.ss.showDocentesC(id)
              .then((result) => {
                let response = result.data;
                this.rol = response.data[0];
                this.rol2 = response.data[0];
              
                this.$refs['view-rol'].show();
                this.isLoading = false;
              })
              .catch(error => {
                console.log(error);
                this.isLoading = false;
              });
          },
          formatDate(fechaAlta) {
            if (fechaAlta) {
              const date = new Date(fechaAlta);
              const day = String(date.getDate()).padStart(2, '0');
              const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
              const year = date.getFullYear();
              return `${day}-${month}-${year}`;
            }
            return '';
          },
        editRol() {
            this.$refs['frm-rolEdit'].show();
            this.$refs['view-rol'].hide();
        },
        cancelRol() {
            if (this.rol.role_id) {
                this.$refs['view-rol'].show();
            }
            this.$refs['frm-rolEdit'].hide();
            this.$refs['view-rol'].show();
        },
        setDocente() {
            try {
                console.log("setdocentes", this.rol.idDocente, this.rol.idGestion, this.auth.user_id);
        
                this.ss.setDocente({
                    idGestion: this.rol.idGestion,
                    idDocente: this.rol.idDocente,
                    usuario: this.auth.user_id
                })
                .then(result => {
                    let response = result.data;
        
                    if (response.success) {
                        this.$bvToast.toast(response.message, {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        });
        
                        console.log(response);
                        this.$refs['frm-rol'].hide();
                        this.$refs['datatable-cdocentes'].reload();
                    } else {
                        this.$bvToast.toast(response.message, {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    let errorMessage = 'Problema al guardar el registro, favor verificar los datos';
        
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
        
                    this.$bvToast.toast(errorMessage, {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    });
                });
            } catch (error) {
                console.error('Error in setDocente:', error);
            }
        }
,        
        updateGestiones() {
            try {
                const id = this.id;
                console.log("updateGestiones", id);
        
                this.ss.updateGestiones({
                    id: id,
                    gestion: this.rol2.gestion,
                    descripcion: this.rol2.descripcion,
                })
                .then(result => {
                    console.log(result);
                    let response = result.data;
        
                    this.$bvToast.toast(response.msg, {
                        title: 'Correcto',
                        variant: 'success',
                        autoHideDelay: 5000
                    });
        
                    console.log(response);
                    this.$refs['frm-rolEdit'].hide();
                    this.$refs['datatable-gestion'].reload();
                })
                .catch(error => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast('Problema al guardar el registro, favor verificar los datos', {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    });
                });
            } catch (error) {
                console.error('Error in updateRol:', error);
            }
        },
        updateDocentesC() {
            try {
                const id = this.id;
                console.log("updateGestiones", id);
        
                this.ss.updateDocentesC({
                    id: id,
                    tipo: this.rol2.tipo,
                    resolucion: this.rol2.resolucion,
                    numero: this.rol2.numero,
                    fechaAlta: this.rol2.fechaAlta,
                    idCategoria: this.rol2.idCategoria,
                })
                .then(result => {
                    console.log(result);
                    let response = result.data;
        
                    this.$bvToast.toast(response.msg, {
                        title: 'Correcto',
                        variant: 'success',
                        autoHideDelay: 5000
                    });
        
                    console.log(response);
                    this.$refs['frm-rolEdit'].hide();
                    this.$refs['datatable-cdocentes'].reload();
                })
                .catch(error => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast('Problema al guardar el registro, favor verificar los datos', {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    });
                });
            } catch (error) {
                console.error('Error in updateRol:', error);
            }
        },
        listDatosDocente(id) {
            id = this.idDocente;
            console.log("estelistDatosDocente", id);
            this.ss.listDatosDocente(id)
                .then((result) => {
                    let response = result.data;
                    console.log("Response:", response);
                    if (response.success && response.data.length > 0) {
                        this.datosDoc = response.data[0]; // Asignar el primer elemento de los datos
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
       getGestiones2() {
            this.ss.getGestiones2().then(
                (result) => {
                    let response = result.data;
                    this.gests = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        removeDocente(id) {
            
             id = this.id; 
        
            this.$swal({
                title: "¿Estás seguro que deseas eliminar?",
                text: "Esta acción es irreversible.",
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                buttons: true,
                dangerMode: true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.ss.removeDocente({ id: id })  
                        .then((result) => {
                            let response = result.data;
                            console.log(response);
                            this.$bvToast.toast(
                                response.msg, // Aquí estás utilizando el mensaje del backend
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )
                            this.$refs['view-rol'].hide();
                            this.$refs['datatable-cdocentes'].reload();
                        })
                        .catch(error => {
                            console.log(error);
                        })
                } else {
                    // No hacer nada si el usuario cancela la eliminación
                }
            });
        },
        
        
        draw() {
            window.$('.btn-datatable-details').on('click', (evt) => {
                const id = window.$(evt.target).closest('button').attr('id').split('_')[1];
                this.showDocentesC(id);
            });
            
            window.$('.btn-datatable-certification').on('click', (evt) => {
                const id = window.$(evt.target).closest('button').attr('id').split('_')[1];
               console.log(id);
               this.showInicioDocente(id);
            });

            window.$('.btn-datatable-certificado').on('click', (evt) => {
              const id = window.$(evt.target).closest('button').attr('id').split('_')[1];
             console.log("certificado"+id);
             this.printCertificado(id);
          });
        }
    },
    watch: {
      
        selectedHitDoc(value) {
            this.rol.idDocente = value.idDocente;
        },
      
    },
    components: {
        dataTable,
        VueBootstrapTypeahead
     
    },
    
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        }else{
            this.auth = persona;
            this.getGestiones2();
            this.getDocentesC();
  
            this.getUA();
        }
    }
};
