import MainService from "@/services/MainService.js";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ProfilePage",
    data() {
        let ss = new MainService();
        return {
            msg: "ProfilePage",
            ss: ss,
            ajax: {
                "url": ss.indexPersona(),
                headers: ss.getToken(),
            },
            auth:{},
            persona: {},
            errorBag: {},
            password: {},
            isLoading: false,
            isLoadingFile:false,
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
        };
    },
    methods: {


      loadFile(input) {
        this.isLoadingFile = true;
        input = event.target;
        if (input.files && input.files[0]) {

            const fileInput = event.target.files[0];
                const fileType = fileInput.type;

                if (!fileType.startsWith('image/')) {

                    this.$swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Solo se permiten archivos de imagen!",
/*                         footer: '<a href="#">Why do I have this issue?</a>'
 */                      });
                    this.isLoadingFile = false;
                    return;
                }

            var reader = new FileReader();
            reader.readAsDataURL(input.files[0]);
            var data = new FormData();
            data.append('File', input.files[0]);
            this.ss.uploadFile(data)
                .then(result => {
                    if (result.data.success) {
                        this.$bvToast.toast(
                            result.data.msg,
                            {
                                title: 'Correcto',
                                variant: 'info',
                                autoHideDelay: 5000
                            }
                        );
                        this.persona.fotoPerfil = result.data.data;
                        this.updateUsuario2();
                    } else {
                        this.$bvToast.toast(
                            result.data.msg,
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                    this.isLoadingFile = false;
                })
                .catch(error => {
                    console.log(error);
                    this.$bvToast.toast(
                        'Error subiendo archivo',
                        {
                            title: 'Oops!',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.isLoadingFile = false;
                });
        }
    },

  showPersona(id) {
    this.user_id = id;
    console.log("se3", id);
    this.isLoading = true;
    this.ss.showPersona(id)
        .then((result) => {
            let response = result.data;
            console.log("Response:", response);
            
            // Assuming response.data is an array, and you want to take the first item
            if (response.success && response.data.length > 0) {
                let docenteData = response.data[0]; // Assuming you want the first item
                this.persona = {
                    user_id: docenteData.user_id,
                    persona: docenteData.persona,
                    CI: docenteData.CI,
                    UnidadAcademica: docenteData.UnidadAcademica,
                    idUnidadAcademica: docenteData.idUnidadAcademica,
                    username: docenteData.username,
                    activo: docenteData.activo,
                    Rol: docenteData.Rol,
                    ApPaterno: docenteData.ApPaterno,
                    ApMaterno: docenteData.ApMaterno,
                    Nombre: docenteData.Nombre,
                    role_id: docenteData.role_id,
                    pers_id: docenteData.pers_id,
                    
                   
                    // Add other properties as needed
                };
                
                // Now docente object is populated with the received data
            }
            
            this.$refs['view-persona'].show(); // Show the form
            this.isLoading = false;
        })
        .catch(error => {
            console.log(error);
            this.isLoading = false;
        });
},
      updateUsuario(id,id2) {
        //this.persona.email = this.persona.CorreoEmi;
        id = this.user_id;
        id2 = this.persona.pers_id;
        console.log("SSSSSS",id2)
        const formDatad = {
            user_id: id,
            pers_id: id2,
            username: this.persona.username,
            role_id: this.persona.role_id,
            idUnidadAcademica: this.persona.idUnidadAcademica,
            ApPaterno: this.persona.ApPaterno,
            ApMaterno: this.persona.ApMaterno,
            Nombre: this.persona.Nombre,
            CI: this.persona.CI,
            activo: this.persona.activo,
            fotoPerfil: this.persona.fotoPerfil,
          
        };

  this.ss.updateUsuario(formDatad).then(
      (result) => {
          console.log(result);
          let response = result.data;
          this.$bvToast.toast(
              response.msg,
              {
                  title: 'Correcto',
                  variant: 'success',
                  autoHideDelay: 5000
              }
          )
          //this.$refs['view-consulta'].show(); //para volver al modal
          console.log(response);
          this.persona.reload();
      })
      .catch((error) => {
          this.errorBag = error.response.data.errors;
          this.$bvToast.toast(
              'Problema al Guardar el Registro, favor verificar los Datos',
              {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000
              }
          )
      });
},

    updateUsuario2(id,id2) {
        //this.persona.email = this.persona.CorreoEmi;
        id = this.user_id;
        id2 = this.persona.pers_id;
        console.log("SSSSSS",id2)
        const formDatad = {
            user_id: id,
            pers_id: id2,
            username: this.persona.username,
            role_id: this.persona.role_id,
            idUnidadAcademica: this.persona.idUnidadAcademica,
            ApPaterno: this.persona.ApPaterno,
            ApMaterno: this.persona.ApMaterno,
            Nombre: this.persona.Nombre,
            CI: this.persona.CI,
            activo: this.persona.activo,
            fotoPerfil: this.persona.fotoPerfil,
        
        };

    this.ss.updateUsuario2(formDatad).then(
    (result) => {
        console.log(result);
        let response = result.data;
        this.$bvToast.toast(
            response.msg,
            {
                title: 'Correcto',
                variant: 'success',
                autoHideDelay: 5000
            }
        )
        //this.$refs['view-consulta'].show(); //para volver al modal
        console.log(response);
        this.persona.reload();
    })
    .catch((error) => {
        this.errorBag = error.response.data.errors;
        this.$bvToast.toast(
            'Problema al Guardar el Registro, favor verificar los Datos',
            {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 5000
            }
        )
    });
    },
  
    },
    mounted() {
      var persona = JSON.parse(localStorage.getItem("persona"));
      if (!persona) {
        this.$router.push("/Login");
      } else {
        this.auth = persona;
  
        if (this.auth.user_id) {
          this.showPersona(this.auth.user_id);
        } else {
          console.error("User ID is not available.");
        }
      }
  
       
    },

};
