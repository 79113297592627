import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";

window.$ = window.jQuery = require("jquery");

export default {
    name: "SistemaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "SistemaPage",
            ss: ss,
            ajax: {
                "url": ss.indexSistema(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N°', orderable: false, searchable: false },
                { data: 'Sistema', name: 'Sistema', title: 'Sistema' },
                { data: 'Abreviacion', name: 'Abreviacion', title: 'Abreviacion' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                }, 
            ],

            sistema:{},
            sistemas: [],
            isLoading: false,
            errorBag: {},
            auth : null,
        };
    },
    methods: {
        newSistema() {
            this.sistema = {};
            this.$refs['frm-sistema'].show();
        },
     
        editSistema(id){
            this.categoria = {};
            this.ss.showSistema(id)
            .then((result) => {
              let response = result.data;
              this.sistema = response.data[0];
              this.$refs['frm-sistema'].show();
            })
            .catch(error => {
              console.log(error);
              this.isLoading = false;
            });
        },

        showSistema(id) {
            this.sistema = {};
            this.isLoading = true;
            this.ss.showSistema(id)
              .then((result) => {
                let response = result.data;
                this.sistema = response.data[0];
                this.$refs['view-sistema'].show();
                this.isLoading = false;
              })
              .catch(error => {
                console.log(error);
                this.isLoading = false;
              });
        },

        cancelShowSistema(){
            this.sistema = {};
            this.$refs['view-sistema'].hide();
        },

      
        cancelStoreSistema() {
            this.sistema = {};
            this.$refs['frm-sistema'].hide();
        },
        saveSistema() {
            this.ss.storeSistema(this.sistema).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.$refs['frm-sistema'].hide();
                    this.$refs['datatable-Sistema'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        
        deleteSistema(id) {

            this.$swal({
                title: "¿Estás seguro que deseas eliminar?",
                text: "Esta acción es irreversible.",
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                buttons: true,
                dangerMode: true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.ss.deleteSistema({ id: id })  
                        .then((result) => {
                            let response = result.data;
                            console.log(response);
                            this.$bvToast.toast(
                                response.msg, 
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )
                            this.$refs['datatable-Sistema'].reload();
                        })
                        .catch(error => {
                            console.log(error);
                        })
                } else {
                    // No hacer nada si el usuario cancela la eliminación
                }
            });
        },
        
        
        draw() {
            window.$('.btn-datatable-Sistema').on('click', (evt) => {
                const buttonId = window.$(evt.target).closest('button').attr('id'); 
                
                if (buttonId.includes('details_')) {
                    const id = buttonId.replace('details_', ''); 
                    this.showSistema(id);
                } 
                else if (buttonId.includes('edit_')) {
                    const id = buttonId.replace('edit_', ''); 
                    this.editSistema(id);
                } 
                else if (buttonId.includes('delete_')) {
                    const id = buttonId.replace('delete_', ''); 
                    this.deleteSistema(id);
                }
            });
        }
    },
    components: {
        dataTable,
     
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        }else{
            this.auth = persona;
        }
    }
};
