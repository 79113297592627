<template>
  <md-card class="md-card-profile">
    <div class="md-card-avatar">
    <!--   <img class="img" src="@/assets/img/faces/marc.jpg" /> -->
      <img class="img" :src=" rutaApp +'storage/fotos/' + persona.fotoPerfil" />
     
    </div>

    <md-card-content>
      <!-- <h6 class="category text-gray">CEO / Co-Founder</h6> -->
      <h4 class="card-title">{{persona.persona}}</h4>
      <p class="card-description">
        
      </p>
      <!-- <md-button class="md-round md-success">Follow</md-button> -->
    </md-card-content>
  </md-card>
</template>
<script>
import MainService from "@/services/MainService.js";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ProfilePage",
    data() {
        let ss = new MainService();
        return {
            msg: "ProfilePage",
            ss: ss,
            ajax: {
                "url": ss.indexPersona(),
                headers: ss.getToken(),
            },
            auth:{},
            persona: {},
            errorBag: {},
            password: {},
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
        };
    },
    methods: {


  showPersona(id) {
    this.user_id = id;
    console.log("se3", id);
    this.isLoading = true;
    this.ss.showPersona(id)
        .then((result) => {
            let response = result.data;
            console.log("Response:", response);
            
            // Assuming response.data is an array, and you want to take the first item
            if (response.success && response.data.length > 0) {
                let docenteData = response.data[0]; // Assuming you want the first item
                this.persona = {
                    user_id: docenteData.user_id,
                    persona: docenteData.persona,
                    CI: docenteData.CI,
                    UnidadAcademica: docenteData.UnidadAcademica,
                    idUnidadAcademica: docenteData.idUnidadAcademica,
                    username: docenteData.username,
                    activo: docenteData.activo,
                    fotoPerfil: docenteData.fotoPerfil,
                    Rol: docenteData.Rol,
                    ApPaterno: docenteData.ApPaterno,
                    ApMaterno: docenteData.ApMaterno,
                    Nombre: docenteData.Nombre,
                    role_id: docenteData.role_id,
                    pers_id: docenteData.pers_id,
                    
                   
                    // Add other properties as needed
                };
                
                // Now docente object is populated with the received data
            }
            
            this.$refs['view-persona'].show(); // Show the form
            this.isLoading = false;
        })
        .catch(error => {
            console.log(error);
            this.isLoading = false;
        });
},
  
  
    },
    computed(){
      this.showPersona(this.auth.user_id);
    },
    mounted() {
      var persona = JSON.parse(localStorage.getItem("persona"));
      if (!persona) {
        this.$router.push("/Login");
      } else {
        this.auth = persona;
  
        if (this.auth.user_id) {
          this.showPersona(this.auth.user_id);
        } else {
          console.error("User ID is not available.");
        }
      }
  
       
    },

};
</script>
<style></style>
