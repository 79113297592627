import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

window.$ = window.jQuery = require("jquery");

export default {
    name: "RolPage",
    data() {
        let ss = new MainService();
        return {
            msg: "RolPage",
            ss: ss,
            ajax: {
                "url": ss.getDocentesDesvinculados(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'idDocente', name: 'idDocente', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N°', orderable: false, searchable: false },
                { data: 'Docente', name: 'Docente', title: 'Docente' },
                { data: 'CI', name: 'CI', title: 'CI' },
                { data: 'UnidadAcademica', name: 'UnidadAcademica', title: 'Unidad Academica' },
                { data: 'Gestion', name: 'Gestion', title: 'Gestión' },
                { data: 'Resolucion', name: 'Resolucion', title: 'Resolución' },
                { data: 'Motivo', name: 'Motivo', title: 'Motivo' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            rols: [],
            rol2:{},
            gests: [],
            rol: [],
            uas: [],
            isLoading: false,
            errorBag: {},
          
            auth : null,
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            datosDoc: null,
            idDocente: '',  // Initialize idDocente here
      Docentes: [],  // Example data array, replace with your actual data
      rol: {
        idDocente: '',  // Initialize rol.idDocente here
        Docente: ''  // Initialize rol.Docente here
      },
      selectedHitDoc: 0 , // Initialize selectedHitDoc here
      datosDoc2: { }
        };
        
    },
    methods: {
      updateDatosDocente22(id) {
        id = this.datosDoc2.id;
        console.log("actualizar",id);
            const formData = {
                id: id,
        
                Gestion: this.datosDoc2.Gestion,
                Motivo: this.datosDoc2.Motivo,
                Resolucion: this.datosDoc2.Resolucion,
                Respaldo: this.datosDoc2.Respaldo,

            };
        
            this.ss.updateDatosDocente22(formData)
                .then(result => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(response.msg, {
                        title: 'Correcto',
                        variant: 'success',
                        autoHideDelay: 5000
                    });
                    console.log(response);
                    this.$refs['view-rol'].hide();
                    this.$refs['datatable-docentesdesvinculados'].reload();
                   
                })
                .catch(error => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast('Problema al guardar el registro, favor verificar los datos', {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    });
                });
        },

    /*   updateDatosDocente22(id) {
        id= this.datosDoc2.id;
        console.log('sss1',id);
        const formData = {
            id: id,
            Docente: this.datosDoc2.Docente,
            UnidadAcademica: this.datosDoc2.UnidadAcademica,
            CI: this.datosDoc2.CI,
            Gestion: this.datosDoc2.Gestion,
            Motivo: this.datosDoc2.Motivo,
            Resolucion: this.datosDoc2.Resolucion,
            Respaldo: this.datosDoc2.Respaldo,
            Activo: this.datosDoc2.Activo,
        };
      
        this.ss.updateDatosDocente22(formData)
            .then(result => {
                let response = result.data;
                this.$bvToast.toast(response.msg, {
                    title: 'Correcto',
                    variant: 'success',
                    autoHideDelay: 5000
                });
                this.listDatosDocente(this.id);
                this.showDesvinculados(this.id);
                this.$refs['view-rol'].hide();
            })
            .catch(error => {
                this.$bvToast.toast('Problema al guardar el registro, favor verificar los datos', {
                    title: 'Error',
                    variant: 'danger',
                    autoHideDelay: 5000
                });
            });
      }, */
      loadFile4(input) {
        this.isLoadingFile = true;
        input = event.target;
        if (input.files && input.files[0]) {
          // Validación del tipo de archivo
          if (input.files[0].type !== 'application/pdf') {
            this.$bvToast.toast(
              'El archivo seleccionado no es un Documento PDF',
              {
                title: 'Oops!',
                variant: 'danger',
                autoHideDelay: 5000
              }
            );
            this.isLoadingFile = false;
            return;
          }
      
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var data = new FormData();
          data.append('File', input.files[0]);
          this.ss.uploadFileDoc(data)
            .then(result => {
              if (result.data.success) {
                this.$bvToast.toast(
                  result.data.msg,
                  {
                    title: 'Correcto',
                    variant: 'info',
                    autoHideDelay: 5000
                  }
                );
                this.datosDoc2.Respaldo = result.data.data;
              } else {
                this.$bvToast.toast(
                  result.data.msg,
                  {
                    title: 'Oops!',
                    variant: 'danger',
                    autoHideDelay: 5000
                  }
                )
              }
              this.isLoadingFile = false;
            })
            .catch(error => {
              console.log(error);
              this.$bvToast.toast(
                'Error subiendo el documento',
                {
                  title: 'Oops!',
                  variant: 'danger',
                  autoHideDelay: 5000
                }
              )
              this.isLoadingFile = false;
            })
            .catch(error => {
              console.log(error);
              this.$bvToast.toast(
                'Error subiendo el documento',
                {
                  title: 'Oops!',
                  variant: 'danger',
                  autoHideDelay: 5000
                }
              )
              this.isLoadingFile = false;
            });
        }
      },
      showDesvinculados2(id) {
        console.log("esteshowDesvinculados", id);
        this.ss.showDesvinculados2(id)
            .then((result) => {
                let response = result.data;
                console.log("Response:", response);
                if (response.success && response.data.length > 0) {
                    this.datosDoc2 = response.data[0];
                    this.$refs['view-rol'].show();
                } else {
                    console.warn("No data received or success is false");
                }
            })
            .catch(error => {
                console.log(error);
                this.isLoading = false;
            });
    }
,    

        draw() {
            window.$('.btn-datatable-DocentesDesvinculados').on('click', (evt) => {
              const data = window.$(evt.target)[0].id;
              console.log(data);
              this.showDesvinculados2(data);
          });
        }
    },
   
    components: {
        dataTable,
        VueBootstrapTypeahead
     
    },
    
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        }else{
            this.auth = persona;
   
        }
    }
};
