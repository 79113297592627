import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";

import Dashboard from "@/pages/Dashboard.vue";
import UserProfile from "@/pages/UserProfile.vue";
import TableList from "@/pages/TableList.vue";
import Typography from "@/pages/Typography.vue";
import Icons from "@/pages/Icons.vue";
import Maps from "@/pages/Maps.vue";
import Notifications from "@/pages/Notifications.vue";
import UpgradeToPRO from "@/pages/UpgradeToPRO.vue";

import Login from "@/pages/Login/Login.vue";


import MenuInscripcion from "@/pages/MenuInscripcion/MenuInscripcionPage.vue";
import Repositorios from "@/pages/Repositorios/RepositoriosPage.vue";

import Rol from "@/pages/Rol/RolPage.vue";
import InicioDocente from '@/pages/InicioDocente/InicioDocentePage.vue'
import InicioDocenteGestion from '@/pages/InicioDocenteGestion/InicioDocenteGestionPage.vue'
import Persona from '@/pages/Persona/PersonaPage.vue'
import Gestiones from '@/pages/Gestiones/GestionesPage.vue'
import DocentesCategorizados from '@/pages/DocentesCategorizados/DocentesCategorizadosPage.vue'
import DocentesDesvinculados from '@/pages/DocentesDesvinculados/DocentesDesvinculadosPage.vue'
import PermanenciaDocente from '@/pages/PermanenciaDocente/PermanenciaDocentePage.vue'
import Profesiones from '@/pages/Profesiones/ProfesionesPage.vue'
import GradoAcademico from '@/pages/GradoAcademico/GradoAcademicoPage.vue'
import CategoriaDocente from '@/pages/CategoriaDocente/CategoriaDocentePage.vue'
import ClasificacionDocente from '@/pages/ClasificacionDocente/ClasificacionDocentePage.vue'
import Profesion from '@/pages/Profesion/ProfesionPage.vue'
import Sistema from '@/pages/Sistema/SistemaPage.vue'
import Universidades from '@/pages/Universidades/UniversidadesPage.vue'



const routes = [
  {
    path: "/",
    component: DashboardLayout,
    // redirect: "/dashboard",
    redirect: "/login",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "user",
        name: "Perfil de Usuario",
        component: UserProfile,
      },
      {
        path: "table",
        name: "Table List",
        component: TableList,
      },
      {
        path: "typography",
        name: "Typography",
        component: Typography,
      },
      {
        path: "icons",
        name: "Icons",
        component: Icons,
      },
      {
        path: "maps",
        name: "Maps",
        meta: {
          hideFooter: true,
        },
        component: Maps,
      },
      {
        path: "notifications",
        name: "Notifications",
        component: Notifications,
      },
      {
        path: "upgrade",
        name: "Upgrade to PRO",
        component: UpgradeToPRO,
      },

      {
        path: "login",
        name: "Login",
        meta: {
          hideFooter: true,
          sinmenu: true,
          sinheader: true,
          login:true
        },
        component: Login,
      },

      {
        path: "inscripcion",
        name: "Menu Inscripcion",
        component: MenuInscripcion,
      },

      {
        path: "repositorios",
        name: "Repositorios",
        component: Repositorios,
      },

      {
        path: "rol",
        name: "Rol",
        component: Rol,
      },
      {
        path: "persona",
        name: "Usuarios",
        component: Persona,
      }
      ,
    {
      path: '/iniciodocente',
      name: 'Inicio Docente',
      component: InicioDocente,
      
    },
    {
      path: '/iniciodocentegestion',
      name: 'Inicio Docente por Gestion',
      component: InicioDocenteGestion,
      
    },
    {
      path: '/gestiones',
      name: 'Lista de Gestiones de Categorización',
      component: Gestiones,
      
    },
    {
      path: '/docentescategorizados',
      name: 'Lista de Docentes Categorizados en la Gestión',
      component: DocentesCategorizados,
      
    }
    ,
    {
      path: '/docentesdesvinculados',
      name: 'Lista de Docentes Desvinculados de la EMI',
      component: DocentesDesvinculados,
      
    }

    ,{
      path: '/clasificaciondocente',
      name: 'Clasificación Docente',
      component: ClasificacionDocente,
      
    }
    ,{
      path: '/permanenciadocente',
      name: 'Permanencia del Docente',
      component: PermanenciaDocente,
      
    }
    ,{
      path: '/categoriadocente',
      name: 'Categoria Docente',
      component: CategoriaDocente,
      
    }
    ,{
      path: '/profesiones',
      name: 'Profesiones',
      component: Profesiones,
      
    }
    ,{
      path: '/gradoacademico',
      name: 'Grado Academico',
      component: GradoAcademico,
      
    },
    {
      path: '/Profesion',
      name: 'Profesion',
      component: Profesion,
      
    },
    {
      path: '/Sistema',
      name: 'Sistema',
      component: Sistema,
      
    },
    {
      path: '/Universidades',
      name: 'Universidades',
      component: Universidades,
      
    }

    ],
  },
];

export default routes;
