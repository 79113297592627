import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";


window.$ = window.jQuery = require("jquery");

export default {
    name: "PersonaPage",
    data() {
        let ss = new MainService();
        
        return {
            msg: "PersonaPage",
            ss: ss,
            base64textString: '',
            ajax: {
                "url": ss.indexPersona(),
                headers: ss.getToken(),
             
            },
            columns: [
                { data: 'user_id', name: 'u.user_id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N°', orderable: false, searchable: false },
              /*   { data: 'URLFoto', searchable: false ,name: 'p.Foto', title: 'Foto', render: function(data,type ,row)
                { return '  <img src="'+row.URLFoto+'" style="width: 100px; height: auto;" />'}, width: '200px'}, */
                { data: 'persona', name: 'p.persona', title: 'Persona', width: '600px',searchable: true},
                { data: 'CI', name: 'p.CI', title: 'Ci', width: '600px',searchable: true},
                { data: 'UnidadAcademica', name: 'ua.UnidadAcademica', title: 'Unidad Académica', width: '600px'},
                { data: 'username', name: 'u.username', title: 'Correo Institucional', width: '600px'},
                { data: 'role_id', name: 'u.role_id', title: 'Rol', width: '600px'},
                { data: 'activo', name: 'u.activo', title: 'Activo', width: '600px'},
              /*   { data: '', name: '', title: 'Rol', width: '600px'}, */
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            unidadAcademicas: [],
            searchPersona: '',

            rols: [],
            uas: [],
            personas: [],
            persona: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            password: {},
            auth : null,
            showModal : false,
            searchTerm: '',
        };
    },
    methods: { 
        applyFilter() {
            this.$refs['datatable-persona'].filter(this.searchTerm);
        },
     
        aplicarFiltros(){

            let r = this.persona.persona     
        
                   
            this.ajax.data = function(d){
                d.persona = r
         
            }
         
            this.$refs['datatable-persona'].reloadAjax(this.ajax);
        },
        
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.persona.Foto = result.data.data;

                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        loadFile2(input ) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.persona.Firma = result.data.data;

                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getRol() {
            this.ss.listRol().then(
                (result) => {
                    let response = result.data;
                    this.rols = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getUA() {
            this.ss.listUA().then(
                (result) => {
                    let response = result.data;
                    this.uas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        newPersonaRRHH(){
            this.persona = {};
            this.$refs['frm-persona-rrhh'].show();
        },

        cancelPersonaRRHH(){
            this.persona = {};
            this.$refs['frm-persona-rrhh'].hide();
        },

        newPersona() {
            this.persona = {};
            this.$refs['frm-persona'].show();
        },
      /*   showPersona(id) {
            this.isLoading=true;
            this.ss.showPersona(id).then(
                (result) => {
                    let response = result.data;
                    this.persona = response.data;
                    this.$refs['view-persona'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        }, */

        showPersona(id) {
            this.user_id = id;
            console.log("se3", id);
            this.isLoading = true;
            this.ss.showPersona(id)
                .then((result) => {
                    let response = result.data;
                    console.log("Response:", response);
                    
                    // Assuming response.data is an array, and you want to take the first item
                    if (response.success && response.data.length > 0) {
                        let docenteData = response.data[0]; // Assuming you want the first item
                        this.persona = {
                            user_id: docenteData.user_id,
                            persona: docenteData.persona,
                            CI: docenteData.CI,
                            UnidadAcademica: docenteData.UnidadAcademica,
                            idUnidadAcademica: docenteData.idUnidadAcademica,
                            username: docenteData.username,
                            activo: docenteData.activo,
                            Rol: docenteData.Rol,
                            ApPaterno: docenteData.ApPaterno,
                            ApMaterno: docenteData.ApMaterno,
                            Nombre: docenteData.Nombre,
                            role_id: docenteData.role_id,
                            pers_id: docenteData.pers_id,
                            
                           
                            // Add other properties as needed
                        };
                        
                        // Now docente object is populated with the received data
                    }
                    
                    this.$refs['view-persona'].show(); // Show the form
                    this.isLoading = false;
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                });
        },

        buscarPersonaRRHH(){
            this.ss.showPersonaCI(this.persona).then(
                (result) => {
                    let response = result.data;
                    if(response.data){
                        this.persona = response.data;
                        this.persona.ApPaterno = this.persona.ApellidoPaterno;
                        this.persona.ApMaterno = this.persona.ApellidoMaterno;
                        this.persona.Nombre = this.persona.Nombres;
                        this.persona.username = this.persona.email;
                        console.log(this.persona);
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto!',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                    }else{
                        this.persona={};
                        this.$bvToast.toast(
                            'Persona No Encontrada',
                            {
                                title: 'Oops!',
                                variant: 'warning',
                                autoHideDelay: 5000
                            }
                        )
                    }
                    //this.$refs['view-consulta'].show(); //para volver al modal
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Buscar, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });

        },
        
        editPersona() {
            this.$refs['frm-personaEdit'].show();
            this.$refs['view-persona'].hide();
        },
        cancelPersona() {
            if (this.persona.id) {
                this.$refs['view-persona'].show();
            }
            this.$refs['frm-persona'].hide();
        },
        savePersona() {
        
            this.ss.storePersona(this.persona).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    if(response.success){
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['frm-persona'].hide();
                        this.$refs['frm-persona-rrhh'].hide();
                        this.$refs['datatable-persona'].reload();
                    }else{
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Oops',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        updateUsuario(id,id2) {
            //this.persona.email = this.persona.CorreoEmi;
            id = this.user_id;
            id2 = this.persona.pers_id;
            console.log("SSSSSS",id2)
            const formDatad = {
                user_id: id,
                pers_id: id2,
                username: this.persona.username,
                role_id: this.persona.role_id,
                idUnidadAcademica: this.persona.idUnidadAcademica,
                ApPaterno: this.persona.ApPaterno,
                ApMaterno: this.persona.ApMaterno,
                Nombre: this.persona.Nombre,
                CI: this.persona.CI,
                activo: this.persona.activo
            };
          
            this.ss.updateUsuario(formDatad).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-personaEdit'].hide();
                    this.$refs['datatable-persona'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deletePersona(id,user_id) {
            id = this.user_id;
            user_id = this.persona.pers_id;


            const formDatad2 = {
                user_id: id,
                pers_id: user_id,
              
            };

            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                buttons: true,
                dangerMode: true,
                
            })
            .then((result) => {
                if (result.isConfirmed) {
                        this.ss.destroyPersona(formDatad2)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-persona'].hide();
                                this.$refs['datatable-persona'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cambiopassword() {
            this.$refs['view-persona'].hide();
            this.$refs['view-password'].show();
        },
        cancelPassword() {
            this.$refs['view-password'].hide();
          /*   this.$refs['view-persona'].show(); */
        },
        changePassword() {
            this.password.persona = this.user_id;
            console.log("contraseña",this.password.persona)
            this.ss.changePassword(this.password)
                .then( result => {
                    let response = result.data;
                    if ( response.success ) {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['view-password'].hide();
                        this.$refs['view-persona'].show();
                    } else {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Oops',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch( error => {
                    console.log( error );
                    this.$bvToast.toast(
                        'Error al guardar el registro',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.errorBag = error.data.errors;
                })
        },
       

       
        draw() {
            window.$('.btn-datatable-Persona').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showPersona(data);
            });
        }
    },
    components: {
        dataTable,
  
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = persona;

        /* if(this.auth.UnidadAcademica == null || this.auth.ApellidoPaterno == null || this.auth.ApellidoMaterno == null || this.auth.Nombres == null || this.auth.email == null || this.auth.CorreoEmi == null || this.auth.CI == null){
            this.$swal.fire({
                title: '<strong>Complete sus datos Personales</strong>',
                icon: 'info',
            });
        } */

      /*   this.ss.showPersona(this.auth.id).then(
            (result) => {
                let response = result.data;
                this.persona = response.data;
                //this.$refs['view-persona'].show();

                if (
                    this.auth.UnidadAcademica == null ||
                    this.auth.ApellidoPaterno == null ||
                    this.auth.ApellidoMaterno == null ||
                    this.auth.Nombres == null ||
                    this.auth.email == null ||
                    this.auth.CorreoEmi == null ||
                    this.auth.CI == null
                  ) {

                    this.showModal = true;
                    this.$swal.fire({
                    title: '<strong>Complete sus datos Personales, Para poder usar correctamente el sistema</strong>',
                    icon: 'info',
                });
                  }
                
                this.isLoading=false;
            }
        ).catch(error => {
            console.log(error);
            this.isLoading=false;
        }); */

        if (!persona) {
            this.$router.push('/Login');
        } else {
 /*            this.getUnidadAcademica(); */
            this.getRol();
            this.getUA();
            this.auth = persona;
        }
    }
};
