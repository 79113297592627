var axios = require('axios');

export default class MainService {

    constructor() {
        var token = JSON.parse(localStorage.getItem('token'));
        axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE;
        if (token) {
            axios.defaults.headers.common = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        } else {
            axios.defaults.headers.common = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    getToken() {
        return axios.defaults.headers.common;
    }

    login(item) {
        return axios.post('api/login', item);
    }

    uploadFile(item) {
        return axios.post('api/uploadFile', item);
    }

    uploadFileDoc(item) {
        return axios.post('api/uploadFileDoc', item);
    }
    loginToken365(item) {
        return axios.post('api/loginToken365', item);
    }

    indexPersona() {
        return axios.defaults.baseURL + 'api/Persona/index';
    }

    showPersona(id) {
        return axios.get('api/Persona/show?id=' + id);
    }

    showPersonaCI(item) {
        return axios.post('api/Persona/showCI', item);
    }

    showPerfil(id) {
        return axios.get('api/Persona/showPerfil?id=' + id);
    }

    listPersona() {
        return axios.get('api/Persona/list');
    }

    listUA() {
        return axios.get('api/Persona/listUA');
    }

    storePersona(item) {
        return axios.post('api/Persona/store', item);
    }


    updateUsuario(item) {
        return axios.post('api/Persona/updateUsuario', item);
    }
    
    updateUsuario2(item) {
        return axios.post('api/Persona/updateUsuario2', item);
    }
    destroyPersona(item) {
        return axios.post('api/Persona/destroy', item);
    }

    select2Persona(q) {
        return axios.get('api/Persona/select2?q=' + q);
    }

    changePassword(item) {
        return axios.post('api/Persona/changePassword', item);
    }

    showUnidadAcademica(id) {
        return axios.get('api/UnidadAcademica/show?id=' + id);
    }

    indexUnidadAcademica() {
        return axios.defaults.baseURL + 'api/UnidadAcademica/index';
    }

    listUnidadAcademica() {
        return axios.get('api/UnidadAcademica/list');
    }

    storeUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/store', item);
    }

    destroyUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/destroy', item);
    }

    showRol(id) {
        return axios.get('api/Rol/show?id=' + id);
    }

    indexRol() {
        return axios.defaults.baseURL + 'api/Rol/index';
    }

    listRol() {
        return axios.get('api/Rol/list');
    }

    storeRol(item) {
        return axios.post('api/Rol/store', item);
    }

    updateRol(item) {
        return axios.put('api/Rol/updateRol', item);
    }

    destroy(item) {
        return axios.post('api/Rol/destroy', item);
    }

    //TipoReporte
    showTipoReporte(id) {
        return axios.get('api/TipoReporte/show?id=' + id);
    }

    indexTipoReporte() {
        return axios.defaults.baseURL + 'api/TipoReporte/index';
    }

    listTipoReporte() {
        return axios.get('api/TipoReporte/list');
    }

    storeTipoReporte(item) {
        return axios.post('api/TipoReporte/store', item);
    }

    destroyTipoReporte(item) {
        return axios.post('api/TipoReporte/destroy', item);
    }

    generateTipoReporte(item) {
        return axios.post('api/TipoReporte/generate', item);
    }
    generateTipoReporte2(item) {
        return axios.post('api/TipoReporte/generate', item);
    }

   

  

    

    //Gestion
    showGestion(id) {
        return axios.get('api/Gestion/show?id=' + id);
    }

    indexGestion() {
        return axios.defaults.baseURL + 'api/Gestion/index';
    }

    listGestion() {
        return axios.get('api/Gestion/list');
    }

    storeGestion(item) {
        return axios.post('api/Gestion/store', item);
    }

    destroyGestion(item) {
        return axios.post('api/Gestion/destroy', item);
    }

     


    //InicioDocente
    showInicioDocente(id) {
        return axios.get('api/InicioDocente/showInicioDocente?id=' + id);
    }

    showDesvinculados(id) {
        return axios.get('api/InicioDocente/showDesvinculados?id=' + id);
    }

    showDesvinculados2(id) {
        return axios.get('api/InicioDocente/showDesvinculados2?id=' + id);
    }


    showReportelist(id) {
        return axios.get('api/InicioDocente/showReportelist?id=' + id);
    }

    storeGestiones(item) {
        return axios.post('api/InicioDocente/storeGestiones', item);
    }

    printCertificado(id){
        return axios.get('api/InicioDocente/printCertificado?id=' + id)
}
    setDocente(item) {
        return axios.post('api/InicioDocente/setDocente', item);
    }

    removeDocente(item) {
        return axios.post('api/InicioDocente/removeDocente', item);
    }

    destroyGestiones(item) {
        return axios.post('api/InicioDocente/destroyGestiones', item);
    }

    showResumen(id) {
        return axios.get('api/InicioDocente/docente?id=' + id);
    }
    showGestiones(id) {
        return axios.get('api/InicioDocente/showGestiones?id=' + id);
    }
    showInicioDocente2(id) {
        return axios.get('api/InicioDocente/showInicioDocente2?id=' + id);
    }

    showDocentesC(id) {
        return axios.get('api/InicioDocente/showDocentesC?id=' + id);
    }


    listHistorial(id) {
        return axios.get('api/InicioDocente/listHistorial?id=' + id);
    }

    listDatosDocente(id) {
        return axios.get('api/InicioDocente/listDatosDocente?id=' + id);
    }

    listDocenciaEMI(id) {
        return axios.get('api/InicioDocente/listDocenciaEMI?id=' + id);
    }
    indexInicioDocente() {
        return axios.defaults.baseURL + 'api/InicioDocente/index';
    }

    indexInicioDocenteGestion() {
        return axios.defaults.baseURL + 'api/InicioDocente/indexGestion';
    }

    getGestiones() {
        return axios.defaults.baseURL + 'api/InicioDocente/getGestiones';
    }
   

    getGestiones2() {
        return axios.get('api/InicioDocente/getGestiones2');
    }

    getDocentesList() {
        return axios.get('api/InicioDocente/getDocentesList');
    }


    getDocentes() {
        return axios.defaults.baseURL + 'api/InicioDocente/getDocentes';
    }

    getDocentesDesvinculados() {
        return axios.defaults.baseURL + 'api/InicioDocente/getDocentesDesvinculados';
    }


    listInicioDocente() {
        return axios.get('api/InicioDocente/list');
    }

    getCategoria() {
        return axios.get('api/InicioDocente/getCategoria');
    }

    getNivel() {
        return axios.get('api/InicioDocente/getNivel');
    }

    storeInicioDocente(item) {
        return axios.post('api/InicioDocente/store', item);
    }
    update(item) {
        return axios.post('api/InicioDocente/update', item);
    }
    updateGestiones(item) {
        return axios.post('api/InicioDocente/updateGestiones', item);
    }

    updateDocentesC(item) {
        return axios.post('api/InicioDocente/updateDocentesC', item);
    }
    updateDatosDocente(item) {
        return axios.post('api/InicioDocente/updateDatosDocente', item);
    }


    updateDatosDocente2(item) {
        return axios.post('api/InicioDocente/updateDatosDocente2', item);
    }

    updateDatosDocente22(item) {
        return axios.post('api/InicioDocente/updateDatosDocente22', item);
    }
    destroyInicioDocente(item) {
        return axios.post('api/InicioDocente/destroy', item);
    }
    

    getdocente(id) {
        return axios.get('api/Calcular/docente?id=' + id);
    }



    storeLogin(item) {
        return axios.post('api/storeLogin', item);
    }
   





    showClasificacion(id) {
        return axios.get('api/Clasificacion/show?id=' + id);
    }

    indexClasificacion() {
        return axios.defaults.baseURL + 'api/Clasificacion/index';
    }

    listClasificacion() {
        return axios.get('api/Clasificacion/list');
    }




    showPermanencia(id) {
        return axios.get('api/Permanencia/show?id=' + id);
    }

    indexPermanencia() {
        return axios.defaults.baseURL + 'api/Permanencia/index';
    }

    listPermanencia() {
        return axios.get('api/Permanencia/list');
    }

    //CategoriaDocente

    indexCategoriaDocente() {
        return axios.defaults.baseURL + 'api/CategoriaDocente/index';
    }

    showCategoriaDocente(id) {
        return axios.get('api/CategoriaDocente/show?id=' + id);
    }

    listCategoriaDocente() {
        return axios.get('api/CategoriaDocente/list');
    }

    storeCategoriaDocente(item) {
        return axios.post('api/CategoriaDocente/store', item);
    }

    deleteCategoriaDocente(item) {
        return axios.post('api/CategoriaDocente/destroy', item);
    }

    //ClasificacionDocente

    indexClasificacionDocente() {
        return axios.defaults.baseURL + 'api/ClasificacionDocente/index';
    }

    showClasificacionDocente(id) {
        return axios.get('api/ClasificacionDocente/show?id=' + id);
    }

    listClasificacionDocente() {
        return axios.get('api/ClasificacionDocente/list');
    }

    storeClasificacionDocente(item) {
        return axios.post('api/ClasificacionDocente/store', item);
    }

    deleteClasificacionDocente(item) {
        return axios.post('api/ClasificacionDocente/destroy', item);
    }

    //Profesion

    indexProfesion() {
        return axios.defaults.baseURL + 'api/Profesion/index';
    }

    showProfesion(id) {
        return axios.get('api/Profesion/show?id=' + id);
    }

    listProfesion() {
        return axios.get('api/Profesion/list');
    }

    storeProfesion(item) {
        return axios.post('api/Profesion/store', item);
    }

    deleteProfesion(item) {
        return axios.post('api/Profesion/destroy', item);
    }

    //Sistema

    indexSistema() {
        return axios.defaults.baseURL + 'api/Sistema/index';
    }

    showSistema(id) {
        return axios.get('api/Sistema/show?id=' + id);
    }

    listSistema() {
        return axios.get('api/Sistema/list');
    }

    storeSistema(item) {
        return axios.post('api/Sistema/store', item);
    }

    deleteSistema(item) {
        return axios.post('api/Sistema/destroy', item);
    }

    //Universidades

    indexUniversidades() {
        return axios.defaults.baseURL + 'api/Universidades/index';
    }

    showUniversidades(id) {
        return axios.get('api/Universidades/show?id=' + id);
    }

    listUniversidades() {
        return axios.get('api/Universidades/list');
    }

    storeUniversidades(item) {
        return axios.post('api/Universidades/store', item);
    }

    deleteUniversidades(item) {
        return axios.post('api/Universidades/destroy', item);
    }
}